.timerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: sans-serif;
  padding: 12px 24px;
  background: linear-gradient(135deg, #ff6b95, #ff4068);
  width: 100%;
  color: white;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.timerLabel {
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

.timerUnits {
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
  z-index: 1;
}

.timeUnit {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  padding: 6px 10px;
  border-radius: 6px;
  min-width: 70px;
}

.timeValue {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  line-height: 1.2;
}

.timeLabel {
  font-size: 0.7rem;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.05em;
}

.heartIcon {
  animation: pulse 1.5s ease-in-out infinite;
}

.compactTimer {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-family: sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0;
}

.compactUnit {
  background: linear-gradient(135deg, #ff6b95, #ff4068);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .timerContainer {
    flex-direction: column;
    gap: 8px;
    padding: 12px 16px;
  }

  .timerUnits {
    gap: 8px;
    width: 100%;
    justify-content: center;
  }

  .timeUnit {
    min-width: auto;
    flex: 1;
    padding: 4px 6px;
  }

  .timeValue {
    font-size: 1.2rem;
  }

  .timeLabel {
    font-size: 0.65rem;
  }
}

@media (max-width: 400px) {
  .timerLabel {
    font-size: 1rem;
  }

  .timeValue {
    font-size: 1.1rem;
  }

  .timeLabel {
    font-size: 0.6rem;
  }
}
