.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);

  @media screen and (orientation: landscape) and (max-width: 1024px) {
    position: relative;
  }
}

.main {
  display: grid;
}
