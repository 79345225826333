.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
}

.required {
  color: red;
}

label {
  margin-top: 1rem;
}

.sendFormButton {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--marketplaceColor);
  color: white;
  border: none;
  cursor: pointer;
  align-self: last baseline;
  width: 200px;
}

.sendFormButton:hover {
  background-color: var(--marketplaceColorDark);
}

.error {
  width: 60%;
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin-top: 1rem;
  border-radius: 5px;
}

.success {
  width: 60%;
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  margin-top: 1rem;
  border-radius: 5px;
}
